import React, { useEffect, useState } from 'react';
import { CardDeck, Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Select from "react-dropdown-select";
import { useHistory } from "react-router-dom";

//local imports
import SceneCard from './scene-card';
import TagEditor from './tag-editor';
import SpinnerLoad from '../common/spinner';
import ItemDisplay from '../common/item-display';
import PaginationComponent from '../common/pagination';
import { helper } from '../common/helper.js';
import FilterScene from '../common/filter-scene';
import NewScene from '../scenes/new-scene-popup';
import NewSceneTag from './new-scene-tag';
import { sceneCardActions } from '../../actions/scene/sceneCardActions.js'
import NewMovie from '../scenes/new-movie-popup';
import ContentRequest from '../tv/ContentRequest';

import '../App.css';

function Scenes(props) {

    const { getScenes } = props;
    const [selectedCount, setSelectedCount] = useState(0)
    const history = useHistory();
    var params = queryString.parse(props.location.search);

    if (params.page == null)
        params.page = 1;
    if (params.size == null)
        params.size = "20";
    if (params.site == null)
        params.site = -1;
    if (params.movie == null)
        params.movie = -1;
    if (params.studio == null)
        params.studio = -1;
    if (params.company == null)
        params.company = -1;
    if (params.dateReleased == null)
        params.dateReleased = "";
    if (params.sort == null)
        params.sort = "date";
    if (params.direction == null)
        params.direction = "desc";
    if (params.search == null)
        params.search = "";
    if (params.modelsort == null)
        params.modelsort = null;
    if (params.sexe == null)
        params.sexe = null;
    if (params.status == null)
        params.status = null;  
    if (params.positionCount == null)
        params.positionCount = "";  

    if (params.tag == null)
        params.tag = [];
    if (!Array.isArray(params.tag))
        params.tag = [params.tag];
    if (params.model == null)
        params.model = [];
    if (!Array.isArray(params.model))
        params.model = [params.model];


    useEffect(() => {
        props.setSceneFilter('SEARCH', params.search);
        props.getScenes(params.size, params.page, params.site, params.sort, params.direction, params.tag, params.model, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
        // eslint-disable-next-line
    }, [getScenes]);

    const tagRefresh = (tagI) => {
        params.tag = tagI;
        props.getScenes(params.size, params.page, params.site, params.sort, params.direction, tagI, params.model, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };

    const siteRefresh = (siteI) => {
        params.site = siteI;
        props.getScenes(params.size, params.page, siteI, params.sort, params.direction, params.tag, params.model, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };

    const movieRefresh = (movieI) => {
        params.movie = movieI;
        props.getScenes(params.size, params.page, params.site, params.sort, params.direction, params.tag, params.model, params.search, movieI, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };

    const studioRefresh = (studioI) => {
        params.studio = studioI;
        props.getScenes(params.size, params.page, params.site, params.sort, params.direction, params.tag, params.model, params.search, params.movie, studioI, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };

    const modelRefresh = (modelI) => {
        params.model = modelI;
        props.getScenes(params.size, params.page, params.site, params.sort, params.direction, params.tag, modelI, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };

    const pageRefresh = (pageNumber) => {
        params.page = pageNumber;
        props.getScenes(params.size, pageNumber, params.site, params.sort, params.direction, params.tag, params.model, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };

    const sizeRefresh = (size) => {
        params.size = size;
        history.push(paramURL(params.site, helper.query(params.model, "model"), helper.query(params.tag, "tag"), params.movie, params.studio, params.company, params.sort, params.direction, size));
        props.getScenes(size, 1, params.site, params.sort, params.direction, params.tag, params.model, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };
    const filterRefresh = (key, item, direction) => {
        history.push(paramURL(params.site, helper.query(params.model, "model"), helper.query(params.tag, "tag"), params.movie, params.studio, params.company, item, direction, params.size));
        if (key === "sort")
            props.getScenes(params.size, params.page, params.site, item, direction, params.tag, params.model, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
        if (key === "direction")
            props.getScenes(params.size, params.page, params.site, params.sort, direction, params.tag, params.model, params.search, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    };
    const sceneRefresh = (value) => {
        params.search = value;
        props.getScenes(params.size, params.page, params.site, params.sort, params.direction, params.tag, params.model, value, params.movie, params.studio, params.company, params.dateReleased, params.modelsort, params.sexe, params.status, params.positionCount);
    }


    const paramURL = (site, model, tag, movie, studio, company, sort, direction, size, ) => {
        return `/scenes?site=${site}&movie=${movie}&studio=${studio}&company=${company}${model}${tag}&size=${size}&sort=${sort}&direction=${direction == null ? params.direction : direction}&dateReleased=${params.dateReleased}&search=${params.search}&positionCount=${params.positionCount}`;
    }

    const onEdit = () =>{
        console.log(props.scen)
        if(props.isEditing){
            props.setEditing(false)
            resetSelection()
        }
        else{
            props.setEditing(true)
        }
    }

    const selectedScenes = (id) =>{
        var arr = []
        arr = props.selectedScene
        arr.push(id)
        props.sceneSelection(arr)
        setSelectedCount(selectedCount + 1)
    }
    const unselectScene = (id) =>{
        var arr = []
        arr = props.selectedScene
        const index = arr.indexOf(id)
        if(index > -1){
            arr.splice(index, 1);
        }
        props.sceneSelection(arr)
        setSelectedCount(selectedCount-1)
    }

    const resetSelection = () =>{
        setSelectedCount(0);
        props.clearSceneSelection();
    }

    var filters = [
        {
            key: "sort",
            title: "Sort By",
            items: [
                { key: "age", value: "Age When Filmed" },
                { key: "date", value: "Date" },
                { key: "length", value: "Length" },
                { key: 'size', value: 'Size' },
                { key: "title", value: "Title" },
                { key: "rating", value: "Rating (Beta)" },
                { key: "view", value: "Most Viewed" },
                { key: "res", value: "Resolution" },
                { key: "id", value: "Recently Added (ID)" },
                { key: "rand", value: "Random" },
                { key: "lastview", value: "Lastly Viewed" }
            ],
            selected: params.sort
        },
        {
            key: "direction",
            title: "Direction",
            items: [
                { key: "asc", value: "ASC" },
                { key: "desc", value: "DESC" }],
            selected: params.direction
        },
        {
            key: "size",
            title: "# Item",
            items: [
                { key: "20", value: "20" },
                { key: "32", value: "32" },
                { key: "50", value: "50" },
                { key: "100", value: "100" }],
            selected: params.size
        }
    ]
    if (filters[2].items.find(a => a.key === filters[2]?.selected) == undefined) {
        filters[2].items.push({ key: params.size, value: params.size });
    }

    if (!props.isFetching.scene) {
        var scenes = props.sceneCard;
        var sceneCardElement = [];
        var i = 0;

        var colSize = 1;
        for (var col = 0; col < Math.ceil(scenes.length / colSize); col++) {
            var cardDeck = [];
            for (var row = 0; row < colSize; row++) {
                if (i < scenes.length) {
                    cardDeck.push(<SceneCard key={row} style={{ width: "350px" }} scene={scenes[i]} siteRefresh={siteRefresh} movieRefresh={movieRefresh} 
                        filterTrue={true} roles={props.perms} selectScene={selectedScenes} unselectScene={unselectScene} editor={props.isEditing} 
                        settings={{
                            "menu": {
                              addToFavorite: true,
                              addToPlaylist: true
                            }
                          }}
                    />);
                    i++;
                }
            }
            sceneCardElement.push(<CardDeck key={col} style={{ padding: "2px" }}>{cardDeck}</CardDeck>);
        }
    }
    return (
        <>
            <ContentRequest />
            <Container fluid >
                <Row>
                    {props.isEditing?<Col xs={1}><TagEditor/></Col>:null}
                    <Col>
                        <Container style={{ maxWidth: `${window.innerWidth}px` }}>
                            <Container>
                                <FilterScene filters={filters} params={params}
                                    baseUrl={`/scenes${helper.tagQuery(params.tag, "tag")}${helper.query(params.model, "model")}&movie=${params.movie}&studio=${params.studio}&company=${params.company}&site=${params.site}&size=${params.size}&dateReleased=${params.dateReleased}&search=${params.search}&positionCount=${params.positionCount}`}
                                    site={siteRefresh} movie={movieRefresh} studioRefresh={studioRefresh} model={modelRefresh} tag={tagRefresh} filterRefresh={filterRefresh} sceneRefresh={sceneRefresh}></FilterScene>
                                <Row className='pb-3'>
                                    <Col md={5}>
                                        <Row>
                                            <Col className="pt-3">{!props.isFetching.count ? <>
                                            <h1 style={{ fontSize: "30px" }}>{`${props.count} Scenes`}</h1>
                                            {props.isEditing?<h3 style={{ fontSize: "15px" }} >{`\n${selectedCount} Selected`}</h3>:null}
                                            </> : <SpinnerLoad />} </Col>
                                            {props.perms.some(e => e.role.id === 1) ? <Col className="pt-3"><NewScene/> <Button style={{ position:"fixed", right: "10px", bottom:"50px", zIndex:30, borderRadius:"30px", fontWeight:"bold" }} onClick={onEdit}>Tags</Button></Col> : <></>}
                                        </Row>
                                    </Col>
                                    {/* {props.perms.some(e => e.role.id === 1) ? <Col className="p-3"><NewMovie /></Col> : <></>} */}
                                    {/* {props.perms.some(e => e.role.id === 1) ? <Col className="p-3"><NewSceneTag/></Col> : <></>} */}
                                    {/* <Col className="d-flex justify-content-end p-3">
                                        <ItemDisplay size={params.size} sizes={[20, 32, 50, 100]}
                                            baseUrl={`/scenes${helper.tagQuery(params.tag, "tag")}${helper.query(params.model, "model")}&sort=${params.sort}&direction=${params.direction}&movie=${params.movie}&site=${params.site}&search=${params.search}&page=1&size=`}
                                            sizeParam={sizeRefresh} />
                                    </Col> */}
                                    <Col md={7}>
                                        <Row className='justify-content-end'>
                                            <Col className="pt-3 p-1" md={5}>
                                                <InputGroup size="sm">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>{filters[0].title}</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Select style={{ minWidth: "180px" }} options={filters[0].items} labelField="value" valueField="key" sortBy="value" values={[filters[0].items.find(a => a.key === filters[0].selected)]}
                                                        onChange={(e) => {
                                                            filterRefresh(filters[0].key, e[0].key, params.direction)
                                                        }} />
                                                </InputGroup>
                                            </Col>
                                            <Col className="pt-3 p-1" md={3}>
                                                <InputGroup size="sm">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>Direction</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Select options={filters[1].items} labelField="value" valueField="key" sortBy="value" values={[filters[1].items.find(a => a.key === filters[1].selected)]}
                                                        onChange={(e) => {
                                                            filterRefresh(filters[1].key, params.sort, e[0]?.key)
                                                        }} />
                                                </InputGroup>
                                            </Col>
                                            <Col className="pt-3 p-1" md={3}>
                                                <InputGroup size="sm">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text># Items</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Select options={filters[2].items} labelField="value" create="true" valueField="key" sortBy="value" values={[filters[2].items.find(a => a.key === filters[2]?.selected)]} onChange={(e) => {
                                                        sizeRefresh(e[0].key)
                                                    }} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <PaginationComponent
                                        baseUrl={`/scenes${helper.tagQuery(params.tag, "tag")}${helper.query(params.model, "model")}&sort=${params.sort}&direction=${params.direction}&movie=${params.movie}&studio=${params.studio}&company=${params.company}&site=${params.site}&size=${params.size}&search=${params.search}&dateReleased=${params.dateReleased}&positionCount=${params.positionCount}&page=`}
                                        params={params} totalItem={props.count} paginate={pageRefresh} />
                                </Row>
                                <Row>{props.isFetching.scene ? <SpinnerLoad /> : null}</Row>
                            </Container>
                            <Container style={{ maxWidth: `${window.innerWidth}px` }}>
                                <Row className="justify-content-center" >{sceneCardElement}</Row>
                                <Row className="justify-content-center">
                                    <PaginationComponent
                                        baseUrl={`/scenes${helper.tagQuery(params.tag, "tag")}${helper.query(params.model, "model")}&sort=${params.sort}&direction=${params.direction}&movie=${params.movie}&studio=${params.studio}&company=${params.company}&site=${params.site}&size=${params.size}&dateReleased=${params.dateReleased}&search=${params.search}&positionCount=${params.positionCount}&page=`}
                                        params={params} totalItem={props.count} paginate={pageRefresh} />
                                </Row>
                            </Container>
                        </Container> 
                    </Col>
                </Row>
            </Container>
            
        </>)
}

const mapStateToProps = (state) => {
    return {
        perms: state.users.permissions.perms,
        isFetching: state.sceneCardReducers.fetching,
        sceneCard: state.sceneCardReducers.scenes,
        count: state.sceneCardReducers.count,
        filters: state.sceneCardReducers.sceneFilters,
        params: state.scenesParamsReducers,
        fetching: state.sceneCardReducers.fetching,
        isEditing: state.sceneCardReducers.isEditing,
        selectedScene: state.sceneCardReducers.selectedScene
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getScenes: (size, page, site, sort, direction, tag, model, search, movie, studio, company, dateReleased, modelsort, sexe, status, positionCount) => dispatch(sceneCardActions.getSceneCard(size, page, site, sort, direction, tag, model, search, movie, studio, company, dateReleased, modelsort, sexe, status, positionCount)),
        setSceneFilter: (param, val) => dispatch(sceneCardActions.setSceneFilter(param, val)),
        setEditing: (edit) => dispatch(sceneCardActions.setSceneEditRequest(edit)),
        sceneSelection:(scenes) => dispatch(sceneCardActions.sceneSelection(scenes)),
        clearSceneSelection:() => dispatch(sceneCardActions.clearAllSceneSelection())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenes);