import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import constants from '../../const'

export default function SceneCardImg(props) {

    const vidRef = useRef(null);
    const previewIMG = `${constants.TRAILER_CDN}${props.scene.posterURL}`
    const [state, setState] = useState({ first: true, img: previewIMG });
    var token = `?token=${localStorage.getItem('token').slice(7)}`;

    const handlePlayVid = () => {
        setState({ ...state, first: false });
        try {
            vidRef.current.play();
        } catch {

        }
    }
    const handlePauseVid = () => {
        setState({ ...state, first: true });
        try {
            vidRef.current.pause();
        }
        catch {

        }

    }
   
    const onHover = () => {
        setState({ ...state, first: false });
    };

    const leaveHover = () => {
        setState({ ...state, first: true });
    };

    const pad = (n, width, z) => {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };

    const playVid = (event) => {
        var video = event.target;
        video.load();
        var playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                video.play();
            }).catch(error => {
                // Auto-play was prevented
                // Show paused UI.
            });
        }
        // event.target.play();
    }

    const pauseVid = (event) => {
        var video = event.target;

        var playPromise = video.play();

        if (playPromise !== undefined) {
            playPromise.then(_ => {
                video.pause();
            }).catch(error => {
                // Auto-play was prevented
                // Show paused UI.
            });
        }
    }

    var siteUrl = "/scenes?site=" + props.site?.id;
    const firstImg = state.first;

    let date = new Date(props.scene?.date);
    date.setDate(date.getDate()+1)
    let formattedDate = date.getDate() + "-" + constants.MONTHS[date.getMonth()] + "-" + date.getFullYear();

    const styleTime = {
        position: 'absolute',
        right: '10px',
        top: '170px',
        paddingRight: "4px",
        paddingBottom: "6px",
        color: "#fff",
        zindex: 20,
        overflow: "hidden",
        cursor: "default",
        opacity: '0.8'
    }
    const styleView = {
        position: 'absolute',
        left: '5px',
        top: '5px',
        paddingRight: "4px",
        paddingBottom: "6px",
        color: "#fff",
        zindex: 20,
        overflow: "hidden",
        cursor: "default",
        opacity: '0.5'
    }
    const styleDate = {
        position: 'absolute',
        paddingLeft:'10px',
        top: '175px',
        height: "25px",
        width: "350px",
        color: "white",
        zindex: 20,
        overflow: "hidden",
        cursor: "default",
        opacity: '1',
        fontSize: '12px',
        backgroundImage: "linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.8) 70%)"
    }
   
    const styleSite = {
        position: 'absolute',
        right: '5px',
        top: '5px',
        paddingRight: "2px",
        color: "white",
        zindex: 20,
        overflow: "hidden",
        cursor: "default",
        opacity: '1',
        fontSize: '12px'
    }
    const styleOverlay = {
        position: 'absolute',
        left: '0px',
        top: '160px',
        height: "40px",
        width: "350px",
        backgound: "black",
        opacity: '0.8',
        zindex: 3,
        cursor: "default",
        backgroundImage: "linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.8) 70%)"
    }

    var vid = <div style={{position:"absolute"}}>
        <video ref={vidRef}
            autoPlay=""
            playsInline={true}
            onClick={event => event.target.play()}
            onDoubleClick={event => event.target.play()}
            onMouseOver={event => playVid(event)}
            onMouseOut={event => pauseVid(event)}
            src={`${constants.TRAILER_CDN}${props.scene.videoURL}`}
            style={{ height: "200px", width: "350px",borderRadius:"20px" }}>
        </video>
        <div style={styleOverlay} onMouseOver={handlePlayVid} />

    </div>


    return (
        <div style={{ height: "200px", width: "350px", backgroundImage:`url(${previewIMG})`, backgroundSize:'contain',borderRadius:"20px" }}
            onMouseOver={onHover}
            onMouseLeave={leaveHover}>
            
            {!firstImg ? vid : null}
            <div style={styleDate}>{`${formattedDate}`}</div>
            <div style={styleTime}>
            {props.scene.status == "SST" || props.scene.status == "SSP" ? <Link to={""} className="badge badge-light"><span><img src={`${constants.CONTENT_DOMAIN}download/sus-media/bodyLocation/camera.png${token}`} style={{ height: '15px' }}></img></span></Link> : <span className="badge badge-light">{`${props.scene?.res}P`}</span>} 
                <span className="badge badge-light">{`${parseFloat(props.scene?.size).toFixed(2)} MB`}</span>
                <span className="badge badge-light">{`${props.scene?.minutes}:${pad(props.scene?.seconds, 2)}`}</span>
            </div>
            <div style={styleSite}>
                <div className="site" >
                    <Link to={siteUrl} className="site-left-box site" onClick={(e) => { if (props?.filters) props.siteRefresh(props.site?.id) }}>{props.site?.shortName}</Link>
                    <Link to={siteUrl} className="site-right-box site" onClick={(e) => { if (props?.filters) props.siteRefresh(props.site?.id) }}>{props.site?.name}</Link>
                </div>
            </div>
            <div style={styleView}>
                <span className="badge badge-light">{`${props.scene?.view} views`}</span>
            </div>
        </div>
    );
}