import axios from 'axios';
import constants from '../const.js';

const getToken = () => {
    return localStorage.getItem('token').slice(7);
}

const getScenes = (size, page, site, sort, direction, tag, model, search, movie, callback, fetchCallback) =>{
    const params={
        size: size,
        page: page,
        site: site,
        movie: movie,
        search: search,
        model: model,
        tag: tag
    } 
        return axios.get(`${constants.DATA1_API_URL}/scenes/${sort}/${direction}`,{params})
        .then(res => {
            callback(res.data);
        })
        .catch(error=> callback(error))
}

const getScenesFilter = (params) =>{

}

const getScenesCount = (params) =>{

}

const getActors = async (id, callback) => {
    return axios.get(`${constants.DATA1_API_URL}/scene/${id}/actors`)
        .then(res => {
            callback(res.data)
        }).catch(e => console.log(e));
}

const insertSceneActor = async (sceneId, actorId) =>{
    return axios.post(`${constants.DATA1_API_URL}/scene/${sceneId}/actors/${actorId}`, {},{
        headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
    })
    .then().catch(e => console.log(e));
}

const removeSceneActor = async (sceneId, actorId) =>{
    return axios.delete(`${constants.DATA1_API_URL}/scene/${sceneId}/actors/${actorId}`,{
        headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
    })
    .then().catch(e => console.log(e));
}

const getTags = async (id, callback) =>{
    return axios.get(`${constants.DATA1_API_URL}/scene/${id}/tags`)
    .then(res => {
        callback(res.data)
    }).catch(e => console.log(e));
}

const getPositions = async (id, callback) =>{
    return axios.get(`${constants.DATA1_API_URL}/scene/${id}/positions`)
    .then(res => {
        callback(res.data)
    }).catch(e => console.log(e));
}



export { getScenes, getScenesFilter, getScenesCount, 
    getActors, insertSceneActor, removeSceneActor,
    getTags, getToken, getPositions }