import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown, Modal, Button, Card } from "react-bootstrap";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteIcon from '@mui/icons-material/Delete';

import { deletePlaylistAPI, removeSceneFromPlaylistAPI, fetchPlaylistBySceneAPI, addSceneToPlaylistAPI } from "../../controller/userController";
import EditPlaylist from "./playlist-edit-popup";
import NewPlaylist from "./playlist-new-popup";

function PlaylistList(props) {
    return <>
        <Card className="mt-2 mb-2 p-1">
            <Container>
                <Row>
                    <Col lg={1} xs={1}>{props.data.privacy == 1 ? <VisibilityIcon /> : <VisibilityOffIcon />}</Col>
                    <Col style={{ fontWeight: 'bold' }}>{props.data.title} </Col>
                    {props.data?.scenes?.length ? <Col lg={3} xs={3}>{props.data?.scenes?.length ? `${props.data?.scenes?.length} scenes` : ''}</Col> : null}
                    <Col xs={3} lg={3} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '-20px' }}>
                        {props.data.in_list == 0 ?
                            <PlaylistAddIcon onClick={() => props.addScene(props.data.id)} /> :
                            <DeleteIcon  onClick={() => props.removeScene(props.data.id)}/>}  
                            <EditPlaylist  playlist={props.data} refresh={()=>props.refresh()} buttonType="verticon"/>
                    </Col>
                </Row>
            </Container>
        </Card >
    </>
}

export default function ScenesDropDownMenu(props) {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddScenePlaylist, setShowAddScenePlaylist] = useState(false);
    const [playlists, setPlaylists] = useState([])


    const fetchUserPlaylistsCallback = (data) => {
        setPlaylists(data);
    }


    const deletePlaylistCallback = () => {
        setShowDeleteModal(false);
        props.refresh()
    }

    const removeFromPlaylistCallback = () => {
        props.refresh();
        console.log(props)
        console.log("Remove from playlist ", props.sceneId, props.settings.pid)
    }


    const addSceneToPlaylistModal = () => {
        setShowAddScenePlaylist(true)
        refreshPlaylist();
    }

    const addScene = async (playlistId) =>{
        await addSceneToPlaylistAPI(playlistId, props.sceneId)
        refreshPlaylist();
    }

    const removeScene = async (playlistId) =>{
        await removeSceneFromPlaylistAPI(playlistId, props.sceneId, console.log())
        refreshPlaylist();
    }

    const refreshPlaylist = () => {
        fetchPlaylistBySceneAPI(props.sceneId, fetchUserPlaylistsCallback);
    }

    return (<>
        {props.settings && props.settings.menu ? <Col md={1}>
            <div className="d-flex flex-row-reverse">
                <Dropdown drop="left">
                    <Dropdown.Toggle variant="" id="dropdown-basic" as={MoreVertIcon} />
                    <Dropdown.Menu>
                        {props.settings.menu.addToFavorite ? <Dropdown.Item>Add to Favorite</Dropdown.Item> : null}
                        {props.settings.menu.addToWatchLater ? <Dropdown.Item>Add to Watch Later</Dropdown.Item> : null}
                        {props.settings.menu.addToPlaylist ? <Dropdown.Item onClick={() => addSceneToPlaylistModal()}>Add to Playlist</Dropdown.Item> : null}
                        {props.settings.menu.removeFromPlaylist ? <Dropdown.Item onClick={() => removeSceneFromPlaylistAPI(props.settings.pid, props.sceneId, removeFromPlaylistCallback)}>Remove from Playlist</Dropdown.Item> : null}
                        {/* <Dropdown.Divider/> */}
                        {props.settings.menu.editPlaylist ? <EditPlaylist playlist={props.settings.playlistInfo} refresh={()=>props.refresh()} buttonType="dropdown" /> : null}
                        {props.settings.menu.deletePlaylist ? <Dropdown.Item onClick={() => setShowDeleteModal(true)}>Delete Playlist</Dropdown.Item> : null}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Col> : null}
        {/* DELETE PLAYLIST CONFIRMATION MODAL */}
        {props.settings?.playlistInfo ?
            <Modal size="md" show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Body>
                    <h5>You really want to delete playlist: {props.settings.playlistInfo.title} ?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button onClick={() => deletePlaylistAPI(props.settings.playlistInfo.id, deletePlaylistCallback)}>OK</Button>
                </Modal.Footer>
            </Modal> : null}
        {props.sceneId ?
            <Modal size="md" show={showAddScenePlaylist} onHide={() => setShowAddScenePlaylist(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Scene To Playlist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {playlists.map(playlist => (
                        <PlaylistList data={playlist} addScene={addScene} removeScene={removeScene} refresh={refreshPlaylist}/>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-auto" style={{ display: "flex" }}>
                        
                        <div style={{ marginRight: "10px" }}><NewPlaylist refresh={refreshPlaylist} buttonType={'modalButton'}/></div>
                    </div>

                    <Button size="sm" onClick={() => setShowAddScenePlaylist(false)}>OK</Button>
                </Modal.Footer>
            </Modal> : null}
    </>)
}
