import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Card, Col, Row } from 'react-bootstrap';
import SceneCardImg from './scene-card-img';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import StyleIcon from '@mui/icons-material/Style';

import '.././App.css';
import EditScene from './edit-scene-info';
import UploadFile from '../common/upload-file1';
import ScenesDropDownMenu from "./ScenesDropDownMenu";
import constants, { TAGS } from '../../const';
import { helper } from '../common/helper';

import { getTags, getPositions } from '../../controller/sceneController';
import SceneHistory from './scene-history';

export default function SceneCard(props) {

    var url = "/scene/" + props.scene.scene.sceneId;
    const [showActor, setShowActor] = useState(false)
    const [showTags, setShowTags] = useState(false)
    const [actorId, setActorId] = useState({ id: 0, url: "" })
    const [pointer, setPointer] = useState({ x: 0, y: 0 })
    const [selected, setSelected] = useState(false)
    const [tags, setTags] = useState({ original: [], selectedTag: [] });
    const [positionHTML, setPositionHTML] = useState([]);

    const category = ["Actor", "Scene", "Role", "Location", "Object", "Outfit"];

    var movieUrl = `/scenes?movie=${!props.scene.movie ? "" : props.scene.movie.id}`;
    var token = `?token=${localStorage.getItem('token').slice(7)}`;
    var filters = false;
    var actors = props.scene.actors
    var editorRole = props?.roles?.some(e => e.role.id === 2)
    var fileRole = props?.roles?.some(e => e.role.id === 12)
    var adminRole = props?.roles?.some(e => e.role.id === 1)
    var deleteRole = props?.roles?.some(e => e.role.id === 14)


    const onmouseMove = (e) => {
        setPointer({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY + 150 })
    }

    const showActorFunc = (actor) => {
        setShowActor(false)
        setActorId({ id: actor, url: `${constants.CONTENT_DOMAIN}download/sus-media/actors/${String(actor).padStart(4, '0')}/model-large.webp${token}` })
    }

    const unshowActorFunc = () => {
        setActorId({})
        setShowActor(false)
    }

    const showTagFunc = async () => {
        getTags(props.scene.scene.sceneId, setTagsCall);
        await getPositions(props.scene.scene.sceneId, positionTimeline);
        setShowTags(true)
    }
    const setTagsCall = (data) => {
        setTags({ ...tags, original: data, selectedTag: data })
    }


    const unshowTagFunc = () => {
        setShowTags(false)
    }

    const actorAge = (actor) => {
        var birthday = new Date(actor.birthday)
        var sceneDate = new Date(props.scene.scene.date);
        var years = new Date(sceneDate - birthday).getFullYear() - 1970;

        return years;
    }
    if (props.filterTrue)
        filters = props.filterTrue

    const handleCheckBoxChange = (event) => {
        if (event.target.checked) {
            props.selectScene(props.scene.scene.sceneId)
        }
        else {
            props.unselectScene(props.scene.scene.sceneId)
        }
        setSelected(event.target.checked)
    }
    const handleTagChecBoxChange = (event) => {
        if (event.target.checked) {
            getTags(props.scene.scene.sceneId, setTagsCall);
            // props.selectScene(props.scene.scene.sceneId)
        }
        else {
            // props.unselectScene(props.scene.scene.sceneId)
        }
        setShowTags(event.target.checked)
    }

    const dupliacateScene = () => {
        axios.get(`${constants.DATA1_API_URL}/scene/${parseInt(props.scene.scene.sceneId)}/duplicate`, {
            headers: { 'Authorization': `${localStorage.getItem('token')}` }
        })
            .then((res) => { })
    }

    const positionTimeline = (positions) => {

        var total = Math.ceil(props.scene.duration * 60)
        const timePer = (time, total) => {
            return Math.ceil(time / total * 100)
        }
        var data = [];
        for (var i = 0; i < positions.length; i++) {
            var start = timePer(positions[i]?.startTime, total)
            var end = timePer(positions[i]?.endTime, total)
            var dur = end - start
            var space;
            if (i == 0) {
                space = timePer(positions[i + 1]?.startTime, total) - 0
                data.push({ type: 'Not Covered', start: timePer(positions[i]?.endTime, total), dur: space })
            }
            else if (i < positions.length - 1) {
                space = timePer(positions[i + 1]?.startTime, total) - timePer(positions[i]?.endTime, total)
                if (space > 0) {
                    data.push({ type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
                    data.push({ type: 'Not Covered', start: timePer(positions[i]?.endTime, total), dur: space })
                }
                else {
                    data.push({ type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
                }
            }
            else {
                space = 100 - timePer(positions[i]?.endTime, total)
                if (space > 0) {
                    data.push({ type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
                    data.push({ type: 'Not Covered', start: timePer(positions[i]?.endTime, total), dur: space })
                }
                else {
                    data.push({ type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
                }
            }
        }
        setPositionHTML(data)

    }

    useEffect(() => {
        if (!props.editor) {
            setSelected(false)
        }
    }, [props.editor])

    return (
        <Card style={{ width: "351px", backgroundColor: `${selected & props.editor ? "#E0E0E0" : "white"}`, borderRadius: "20px" }} onMouseMove={onmouseMove}>
            {showActor ?
                <Link key={actorId.id} to={"/actor/" + actorId.id} style={{ position: "absolute", display: "block", zIndex: '50', left: `${(pointer.x)}px`, top: `${pointer.y}px` }}>
                    <img src={actorId.url} alt="Actor" style={{ width: "67px", height: "90px" }}></img>
                </Link> : null}

            {showTags ?
                <div style={{ position: "absolute", display: "block", zIndex: '20', left: `0px`, paddingTop: '10px', paddingBottom: '10px', top: `20px`, width: "400px", backgroundColor: "white", borderRadius: "5px", opacity: '.85' }}>
                    {category.map((cat, idx) => (
                        <Row key={`${cat}${idx}`}>
                            <Col xs={4} sm={4} md={2} style={{ fontSize: "10px", fontWeight: "bold" }}>{cat}</Col>
                            <Col>
                                <Row>
                                    {helper.findTag(TAGS.SCENECATEGORY, cat, tags.selectedTag).map((tag) =>
                                    (<div key={tag.id} className="m-1 badge badge-secondary" style={{ fontSize: "10px" }}>
                                        <span> <div value={tag.id} style={{ color: "white" }}>{tag.name}</div> </span>
                                    </div>))}
                                </Row>
                            </Col>
                        </Row>))}
                    <Row>
                        <Col>
                            <div className="progress">
                                {positionHTML.map(d => {
                                    return <div className={`progress-bar ${d.type == 'Covered' ? 'bg-success' : 'bg-secondary'}`} role="progressbar" style={{ width: `${d.dur}%` }} aria-valuenow={d.dur} aria-valuemin="0" aria-valuemax="100">|</div>
                                })}
                            </div>
                        </Col>
                    </Row>
                </div> : null}

            <SceneCardImg key={props.scene.scene.id} scene={props.scene.scene} site={props.scene.site} filters={filters} siteRefresh={props.siteRefresh} />
            <div className="card-body d-flex flex-column" style={{ padding: "2px", paddingBottom: "0px" }} to={url} >

                <Row className="ml-1" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    {actors != null ? <>{actors.map(act =>
                        <Link key={act.id} to={"/actor/" + act.id} onMouseEnter={() => showActorFunc(act.id)} onMouseLeave={() => unshowActorFunc()}><div className="text-left text-dark" style={{ fontSize: "11px", marginRight: "5px" }} >{act.name}{act.birthday != null ? `(${actorAge(act)})` : ''}, </div></Link>
                    )}</> : <></>}

                </Row>
                <Row className="pl-1 pt-1">
                    <Col >
                        <Link to={url} className="scene-card-link">
                            <div className="card-title h5" style={{ fontSize: "15px" }}>{props.scene.scene.title}</div>
                        </Link>
                    </Col>
                    <ScenesDropDownMenu type={"PL-S"} settings={props.settings} sceneId={props.scene.scene.id} refresh={props.refresh} />
                </Row>


                <div className="d-flex justify-content-between mt-auto align-content-end flex-wrap">
                    <div className="d-flex justify-content-between mt-auto align-content-end flex-wrap" style={{ position: "-10px" }}>
                        {props.scene.movie ?
                            <div>{props.scene.movie.id !== 0 ?
                                <div className="site">
                                    <Link to={movieUrl} className="site-left-box site" onClick={(e) => { if (filters) props.movieRefresh(props.scene.movie.id) }}>{props.scene.movie.scenesNumber}</Link>
                                    <Link to={movieUrl} className="site-right-box site" onClick={(e) => { if (filters) props.movieRefresh(props.scene.movie.id) }}>{props.scene.movie.title}</Link>
                                </div> : <div />}
                            </div>
                            : <div />}

                    </div>
                </div>
                <Row>
                    {props.editor ? <div className="col">
                        {fileRole ? <input type="checkbox" onChange={handleCheckBoxChange}></input> : null}
                    </div> : null}
                    <div className=" col justify-content-end d-flex mt-auto">
                        {fileRole ? <a href={`${constants.CONTENT_DOMAIN}${props.scene.scene.videoFull}${token}`} >Vid</a> : null}
                        {fileRole ? <a href={`H:/sus-media/scenes/${props.scene.scene.sceneId}/vid`} >Vid</a> : null}
                        {fileRole ? <a href={`H:/sus-media/scenes/${props.scene.scene.sceneId}`} >Files</a> : null}

                        {!adminRole && (props.scene.scene.status !== "COM" && props.scene.scene.status !== "SST") ? <Link to={url} className={`badge badge-pill badge-${props.scene.scene.status === 'IPI' | props.scene.scene.status === 'REF' ? 'info' : 'danger'}`}>{props.scene.scene.status === 'IPI' | props.scene.scene.status === 'REF' ? 'Partial' : 'Missing'}</Link> : <div />}
                        {adminRole && (props.scene.scene.status !== "COM" && props.scene.scene.status !== "SST") ? <Link to={url} className="badge badge-pill badge-danger">{props.scene.scene.status}</Link> : <div />}
                        {fileRole ? <><StyleIcon sx={{ fontSize: "25px", zIndex:'30', color: `${props.scene.positionIds?.length > 0 ? '#35c435' : ''}` }} onMouseEnter={showTagFunc} onMouseLeave={unshowTagFunc} /><span style={{ color: '#35c435', fontWeight: 'bold' }}>{props.scene.positionIds?.length}</span></> : null}
                        {adminRole && deleteRole ? <DeleteIcon sx={{ fontSize: "25px" }} onClick={() => dupliacateScene()} /> : null}
                        {adminRole && (props.scene.scene.view > 0) ? <SceneHistory sceneId={props.scene.scene.sceneId} /> : null}
                        {fileRole ? <UploadFile sceneView={false} sceneId={props.scene.scene.sceneId} poster={props.scene.scene.posterURL} /> : null}
                        {adminRole ? <EditScene sceneView={false} sceneId={props.scene.scene.sceneId} /> : null}
                    </div>
                </Row>
            </div>
        </Card>
    )
}