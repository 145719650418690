import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { Col, Row, CardDeck, Container, Button } from "react-bootstrap";
import RefreshIcon from '@mui/icons-material/Refresh';

import constants from "../../const";
import PositionCard from "./position-card.js";
import PositionChooser from "./position-chooser.js";

function PositionAdder(props) {
    var token = "";
	if (localStorage.getItem("token") != null) {
		token = localStorage.getItem("token");
	}
	var tokenUrl = `?token=${token.slice(7)}`;

    const [allPositions, setAllPositions] = useState([]);
    const [allPositionTags, setAllPositionTags] = useState([]);
    const [scenePositions, setScenePositions] = useState([]);
    const [posCards, setPosCards] = useState([]);
    const [positionBar, setPositionBar] = useState([]);

  const insertScenePosition = async (position) =>{
    await axios.post(`${constants.DATA1_API_URL}/scene/${props.sceneId}/positions`,position,{
        headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
    });
    fetchScenePositions()
  }

  const updateScenePosition = async (position) =>{
    await axios.put(`${constants.DATA1_API_URL}/scene/${props.sceneId}/positions`, position,{
        headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
    });
    fetchScenePositions()
  }

  const removeScenePosition = async (position) =>{
    await axios.delete(`${constants.DATA1_API_URL}/scene/${props.sceneId}/positions/${position.id}`,{
        headers:{ 'Authorization' : `${localStorage.getItem('token')}` }
    });

    fetchScenePositions()
  }

  const fetchScenePositions = () =>{
    axios.get(`${constants.DATA1_API_URL}/scene/${props.sceneId}/positions`).then((res)=>{
        setScenePositions(res.data)
        positionTimeline(res.data)  
        
    })
    populateCards()

  }
  
  const fetchPositions = () =>{
    axios.get(`${constants.DATA1_API_URL}/scenes/positions`).then((res)=>{
        setAllPositions(res.data)
    })
  }

  const fetchPositionCategory = () =>{
    axios.get(`${constants.DATA1_API_URL}/scenes/tag-position`).then((res)=>{
        setAllPositionTags(res.data)
    })
  }
  const positionTimeline = (positions) =>{

    var total = Math.ceil(props.duration)
    const timePer = (time, total) =>{
        return Math.ceil(time/total * 100)
    }
    var data = [];
    for (var i  = 0; i< positions.length; i++){
        var start = timePer(positions[i]?.startTime, total)
        var end = timePer(positions[i]?.endTime, total)
        var dur = end - start
        var space;
        if( i == 0 ){
            space = timePer(positions[i+1]?.startTime, total) - 0
            data.push({type: 'Not Covered', start: timePer(positions[i]?.endTime, total), dur: space })
        }
        else if (i < positions.length - 1){
            space = timePer(positions[i+1]?.startTime, total) - timePer(positions[i]?.endTime, total)
            if (space > 0){
                data.push({type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
                data.push({type: 'Not Covered', start: timePer(positions[i]?.endTime, total), dur: space })
            }
            else{
                data.push({type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
            }
        }
        else{
            space = 100 - timePer(positions[i]?.endTime, total)
            if (space > 0){
                data.push({type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
                data.push({type: 'Not Covered', start: timePer(positions[i]?.endTime, total), dur: space })
            }
            else{
                data.push({type: 'Covered', start: timePer(positions[i]?.startTime, total), dur: dur })
            }
        }
    }
    setPositionBar(data)

}

  const selectPosition = (position)=>{
    insertScenePosition({
      scene: props.sceneId,
      position: position.position,
      startTime: 0,
      endTime: 0
  })
}

  useEffect(() => {
    fetchPositions();
    fetchPositionCategory();
    
  }, [props.scenePositions]);

  useEffect(()=>{
    if(props.scenePositions.length>0)
    setScenePositions(props.scenePositions)
    populateCards()
  },[props.scenePositions])

  

  const populateCards = () =>{
    var positionCards = [];
    var i = 0;

    if (Array.isArray(scenePositions)) {
      var colSize = 1;
      for (var col = 0; col < Math.ceil(scenePositions.length / colSize); col++) {
        var cardDeck = [];
        for (var row = 0; row < colSize; row++) {
          if (i < scenePositions.length) {
            if (scenePositions[i] !== null)
              cardDeck.push(
                <PositionCard
                  key={`${row}-${col}`}
                  style={{ width: "200px" }}
                  position={scenePositions[i]}
                  positionList={allPositions}
                  positionTagList={allPositionTags}
                  roles={props.perms}
                  refresh={fetchScenePositions}
                  update={updateScenePosition}
                  remove={removeScenePosition}
                  jumpVideo={props.jumpVideo}
                />
              );
            i++;
          }
        }
        positionCards.push(
          <CardDeck key={col} style={{ padding: "5px" }}>
            {cardDeck}
          </CardDeck>
        );
      }
      setPosCards(positionCards)
    }
    
  }

 

  return (
    <>
      <Container fluid>
      {props.perms.some(e => e.role.id === 2) ? <Row className="m-1">
        <PositionChooser currentPosition={{
        id: 1,
        scene: props.sceneId,
        position: 1,
        name: "Add Position",
    }} positionList={allPositions} positionTagList={allPositionTags} select={selectPosition} setPositionLoc={props.setPositionLoc} isMobile={props.isMobile}/>
            <Button className={`btn btn-sm btn-light`} onClick={fetchScenePositions}><RefreshIcon/></Button>
            <Col>
            <div className="progress">
                            {positionBar.map(d =>{
                                return <div className={`progress-bar ${d.type =='Covered'? 'bg-success':'bg-secondary'}`} role="progressbar" style={{ width: `${d.dur}%`}} aria-valuenow={d.dur} aria-valuemin="0" aria-valuemax="100">|</div>
                            })}
                        </div>
            </Col>
        </Row>:null}
        <Row>{posCards}</Row>
      </Container>
      
    </>
  );
}

const mapStateToProps = (state) => {
  return {
      perms: state.users.permissions.perms
  }
}

const mapDispatchToProps = (dispatch) => {

}
export default connect(mapStateToProps, mapDispatchToProps)(PositionAdder)