import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';


import VideoPlayer from './video-player';

export default function MultiPlayer(props) {

    const [play, setPlay] = useState(false);
    const [muted, setMuted] = useState(false);
    const [seek, setSeek] = useState(0);

    const playVid = () => {
        if (play) {
            setPlay(false);
        }
        else {
            setPlay(true)
        }
    }

    const muteVid = () => {
        if (muted) {
            setMuted(false)
        }
        else {
            setMuted(true)
        }
    }

    const seekVid = () => {
        setSeek(seek + 1)
    }

    const preferred_ratio = 1.60;
    var num_rows = 0;
    var num_cols = 0;

    const calc_cols_rows = (screenHeight, screenWidth, num_items) =>{
        var desired_aspect = (screenWidth / screenHeight) / preferred_ratio;

        num_rows = Math.round(Math.sqrt(num_items / desired_aspect));
        num_cols = Math.ceil(num_items / num_rows);
        console.log(num_rows)
        console.log(num_cols)
    }


    return <div style={{ "width": window.innerWidth, "height": window.innerHeight }} className='flex-container'>
        {calc_cols_rows(window.innerHeight, window.innerWidth, props.scenes.length)}
        <Button style={{ right: "140px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => playVid()}>{play ? <PauseIcon /> : <PlayArrowIcon />}</Button>
        <Button style={{ right: "70px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => muteVid()}>{muted ? <VolumeOffIcon /> : <VolumeUpIcon />}</Button>
        <Button style={{ right: "10px", bottom: "50px", position: "fixed", zIndex: 30, borderRadius: '30px' }} className={"m-1"} onClick={() => seekVid()}><FastForwardIcon /></Button>
        <div>
            {props.scenes.map(scene => (
                <VideoPlayer scene={scene} play={play} muted={muted} seek={seek} width={window.innerWidth/num_cols} height={window.innerHeight/num_rows}/>))}
        </div>
    </div>
}