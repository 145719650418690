import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import queryString from 'query-string';


import '../App.css';

import ImageGalleryView from './image-gallery-view';

function GalleryView(props) {

    var params = queryString.parse(props.location?.search);

    if(props?.match == null){
        params.id = props.name
    }
    else{
        params.id = props.match.params.id
    }

    const galOBJ = {
        id: 1,
        title:`Gallery ${params.id}`,
        actors:[{
            id: 1,
            name: "Unkown Female",
            sexe: "F"
        }],
        date: "2023-10-10T00:00:00.000Z"
    }
    

    document.title = `${galOBJ.id}|${galOBJ.title}`
    let date = dayjs(galOBJ.date);
    

    var actorElem = [];
    for (var i = 0; i < galOBJ.actors.length; i++) {
        var actorUrl = `/actor/${galOBJ.actors[i].id}`;
        if (i >= 0 && i < galOBJ.actors.length - 1) {
            actorElem.push(<Link key={i} to={actorUrl}><span style={{ fontSize: "14px", marginRight: "5px" }}>{galOBJ.actors[i].name},  </span></Link>);
        }
        else {
            actorElem.push(<Link key={i} to={actorUrl}><span style={{ fontSize: "14px", marginRight: "5px" }}>{galOBJ.actors[i].name}</span></Link>);
        }
    }

        return ( <>
        <Container style={{ maxWidth: `${window.innerWidth}px` }}>

        {/* {scene.scene.id == 0 && props.error !== '' ? <Redirect to='/scenes'></Redirect> : <></>} */}
        <Container>
            <Row style={{ marginTop: "50px" }} className="justify-content-between">
                <h2>{galOBJ.title}</h2>
                {/* {scene.scene.statusName != 'T' ? <Link className={`btn btn-sm btn-danger m-2`}>{scene.scene.statusName}</Link> : null} */}

                {/* <SceneUserData sceneId={props.match.params.id}></SceneUserData> */}
            </Row>
            <Row style={{ marginTop: "5px" }}>{actorElem}</Row>
            <Row><div className="text-muted" style={{ fontSize: "15px" }}>{date.format("D-MMM-YYYY")}</div></Row>
            <Row className="justify-content-between">
                <div style={{ width: "100%", height: "100%", }}>
                    <ImageGalleryView id={params.id} params={params}/>
                </div>
            </Row>
            <Row>
                <Col sm={3} md={4} style={{ marginLeft: "20px" }}>
                    
                   
                    <Row className="">
                        {/* <a href={`/scenes?site=${props.scene.site.siteId}`} className="site site-scene">
                            <div className="site-left-box site">{props.scene.site.shortname}</div>
                            <div className="site-right-box site">{props.scene.site.name}</div>
                        </a> */}
                        {/* {props.scene.movie.id != 0 ?
                            <a href={`/scenes?movie=${props.scene.movie.id}`} className="site site-scene">
                                <div className="site-left-box site">{props.scene.movie.scenesNumber}</div>
                                <div className="site-right-box site">{props.scene.movie.title}</div>
                            </a> : <div />} */}
                    </Row>
                </Col>
                <Col>
                    {/* <Row className="justify-content-between">
                        <Col><h3>Tags</h3></Col>
                        {props.perms.some(e => e.role.id === 12) ? <Col><UploadFile sceneId={scene.scene.id} /></Col> : null}
                        {props.perms.some(e => e.role.id === 2) ? <Col><EditScene sceneView={true} sceneId={scene.scene.id} /></Col> : null}
                        <Col><button type="button" className={`btn btn-sm btn-success`} onClick={refreshScene}>Refresh</button></Col>
                    </Row> */}
                    {/* {!props.isFetchingSceneTag ? category.map((cat, idx) => (
                        <Row key={`${cat}${idx}`}>
                            <Col xs={3} sm={3} md={2}>{cat}</Col>
                            <Col>
                                <Row>
                                    {helper.findTag(TAGS.SCENECATEGORY, cat, props.sceneTag).map((tag) =>
                                    (<div key={tag.id} className="m-1 badge badge-secondary">
                                        <span> <Link style={{ color: "white" }} to={`/scenes?tag=${tag.id}`}>{tag.name}</Link> </span>
                                    </div>))}
                                </Row>
                            </Col>
                        </Row>)) : <div><SpinnerLoad></SpinnerLoad></div>} */}
                </Col>
            </Row>
            <Row>
                {/* <div className="text-left mt-4" style={{ fontSize: "16px" }}>{scene.scene.description}</div> */}
            </Row>
            {/* <Row><a href={`${constants.CONTENT_DOMAIN}dload/${scene.scene.videoUrl}${token}`} onClick={()=>props.downloadHistory(scene.scene.id)}>Download</a></Row> */}
            {/* <Row><RelatedScenes /></Row> */}
        </Container>

    </Container></>)
}

export default GalleryView;