import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import constants from "../../const";

export default function VideoPlayer(props) {

    const vidRef = useRef(null);
    var token = `?token=${localStorage.getItem('token').slice(7)}`;

    const seek = (t) =>{
        vidRef.current.currentTime = t+vidRef.current.currentTime
    }

    useEffect(()=>{
        if(props.play){
            vidRef.current.play();
        }
        else{
            vidRef.current.pause();
        }
    },[props.play])

    useEffect(()=>{
        seek(20)
    },[props.seek])

    return <>
        <video ref={vidRef} id="myvideo" controlsList="nodownload"
            poster={`${constants.TRAILER_CDN}${props.scene.scene.posterURL}`} controls style={{ width: `${props.width-100}px`, height: `${props.height-100}px` }} muted={props.muted} >
            <source src={`${constants.CONTENT_DOMAIN}${props.scene.scene.videoFull}${token}`} />
        </video>
    </>
}