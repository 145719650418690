import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import { Container, Row, Col, CardDeck } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import dayjs from 'dayjs';

import '../App.css';

import { sceneActions } from '../../actions/scene/sceneActions.js';
import { userDataActions } from '../../actions/scene/userDataActions';
import SpinnerLoad from '../common/spinner';

import constants, { TAGS } from '../../const';
import SceneCard from '../scenes/scene-card';
import UploadFile from '../common/upload-file1';
import SceneUserData from '../user/scene-user-data';
import EditScene from '../scenes/edit-scene-info';
import { helper } from '../common/helper';
import ContentRequest from '../tv/ContentRequest';
import PositionAdder from './position-adder.js';

function SceneView(props) {
    var utc = require('dayjs/plugin/utc')
    dayjs.extend(utc)

    const { getScene, getSceneTag } = props;
    const [scenePositions, setScenePositions] = useState([]);
    const [errorFlag, setErrorFlag] = useState(false);
    const vidRef = useRef(null)

    const fetchScenePositions = () =>{
        axios.get(`${constants.DATA1_API_URL}/scene/${parseInt(props.match.params.id)}/positions`).then((res)=>{
            setScenePositions(res.data)
        })
      }

    useEffect(() => {
        fetchScenePositions()
        getScene(parseInt(props.match.params.id));
        getSceneTag(parseInt(props.match.params.id));
        fetchScenePositions()
        // eslint-disable-next-line
    }, [props.match.params.id]);



    useEffect(() => {
        getCurrentTime();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "//cdn.delight-vr.com/latest/dl8-d64cee8d26e5269fea818f00483604884ade3832.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const category = ["Scene", "Role", "Location", "Object", "Outfit"];

    const updateErrorFlag = () =>{
        setErrorFlag(true);
        props.flagScene(scene.scene.id)
        props.getScene(scene.scene.id);
    }
    const getCurrentTime = () => {
        var vid = document.getElementById("myvideo");
        props.addToHistory(parseInt(props.match.params.id), vid.currentTime);
    };

    const jumpVideo = (value) =>{
        vidRef.currentTime = value;
        var vid = document.getElementById(`myvideo`);
        vid.currentTime = value;
    }

    const RelatedScenes = () => {
        var relatedScenes = [];
        if (props.relScenes.movie.count > 0) {
            var movieSceneCard = [];
            props.relScenes.movie.scenes.forEach(scene => {
                movieSceneCard.push(<CardDeck key={scene.scene.id} className="relcard"><SceneCard scene={scene} /></CardDeck>);
            });
            relatedScenes.push(
                <Container key="movie-0" style={{ maxWidth: `${window.innerWidth}px`, overflowY: 'auto' }}>
                    <Row className="justify-content-center"><h3>{`${props.relScenes.movie.count} Scenes with ${props.scene.movie.title}`}</h3></Row>
                    <Row> <div className="scrollcards">{movieSceneCard}</div></Row>
                </Container>);
        }
        if (props.relScenes.models.length > 0) {
            var relScenes = [];
            props.relScenes.models.forEach(model => {
                var modelSceneCard = [];
                model.scenes.forEach(scene => {
                    modelSceneCard.push(<CardDeck key={scene.scene.id} className="relcard"><SceneCard scene={scene} /></CardDeck>);
                });
                relScenes.push(
                    <div key={`model-${model.models[0].name}`} style={{ maxWidth: `${window.innerWidth}px`, backgroundColor: "#787878" }} className="container">
                        <Row className="justify-content-center"><h3>{`${model.count} Scenes with ${model.models[0].name}`}</h3></Row>
                        <div className="row">
                            <div className="scrollcards">{modelSceneCard}</div>
                        </div>
                    </div>)

            });
            relatedScenes.push(relScenes);
        }
        if (props.relScenes.site.count > 0) {
            var siteSceneCard = [];
            props.relScenes.site.scenes.forEach(scene => {
                siteSceneCard.push(<CardDeck key={scene.scene.id} className="relcard"><SceneCard scene={scene} /></CardDeck>);
            });
            relatedScenes.push(
                <Container key="site-0" style={{ maxWidth: `${window.innerWidth}px` }}>
                    <Row className="justify-content-center"><h3>{`${props.relScenes.site.count} Scenes with ${props.scene.site.name}`}</h3></Row>
                    <Row><div className="scrollcards">{siteSceneCard}</div></Row>
                </Container>);
        }
        return relatedScenes
    }

    if (!props.isFetching) {
        var token = `?token=${localStorage.getItem('token').slice(7)}`;
        var scene = props.scene.scene;
        document.title = `${scene.scene.id}|${scene.scene.title}`
        let date = dayjs(scene.scene.date);
        var actorElem = [];
        for (var i = 0; i < props.scene.actors.length; i++) {
            var actorUrl = `/actor/${props.scene.actors[i].id}`;
            if (i >= 0 && i < props.scene.actors.length - 1) {
                actorElem.push(<Link key={i} to={actorUrl}><span style={{ fontSize: "14px", marginRight: "5px" }}>{props.scene.actors[i].name},  </span></Link>);
            }
            else {
                actorElem.push(<Link key={i} to={actorUrl}><span style={{ fontSize: "14px", marginRight: "5px" }}>{props.scene.actors[i].name}</span></Link>);
            }
        }

        var videoHTML = null;
        if (props.sceneTag.some(e => e.id === "469")) {
            if (!props.isFetchingUserInfo) {

                videoHTML =
                    <dl8-video id="myvideo" ref={vidRef}
                        format={`${scene.scene.vrType === 'none' ? 'STEREO_180_LR' : scene.scene.vrType}`}
                        cors-fallback-url={`${constants.CONTENT_DOMAIN}vr?file=${constants.CONTENT_DOMAIN}${scene.scene.videoUrl}${token}#t=${props.startTime}&${constants.CONTENT_DOMAIN}${scene.scene.posterUrl}${token}`}
                        preferred-controls="MAGIC_WINDOW_CONTROLS"
                        poster={`${constants.CONTENT_DOMAIN}${scene.scene.posterUrl}${token}`}>
                        <dl8-action-button></dl8-action-button>
                        <source src={`${constants.CONTENT_DOMAIN}${scene.scene.videoUrl}${token}#t=${props.startTime}`} type="video/mp4" />
                    </dl8-video>
            }
        }
        else {
            if (!props.isFetchingUserInfo) {
                videoHTML =
                    <video id="myvideo" ref={vidRef} controlsList="nodownload"
                        playsInline={true}
                        poster={`${constants.CONTENT_DOMAIN}${scene.scene.posterUrl}${token}`} controls style={{ width: "100%", height: "100%" }} onPlay={() => { getCurrentTime() }}>
                        <source src={`${constants.CONTENT_DOMAIN}${scene.scene.videoUrl}${token}#t=${props.startTime}`} />
                    </video>;
            }
        }


        return (<>
            <ContentRequest />
            <Container style={{ maxWidth: `${window.innerWidth}px` }}>

                {scene.scene.id === 0 && props.error !== '' ? <Redirect to='/scenes'></Redirect> : <></>}
                <Container>
                    <Row style={{ marginTop: "50px" }} className="justify-content-between">
                        <h2>{scene.scene.title}</h2>
                        {scene.scene.statusName !== 'COM' & scene.scene.statusName !== 'UE'? <div className={`badge-pill btn-sm m-2 badge-${scene.scene.statusName==='REF'|scene.scene.statusName==='IPI'?'warning':'danger'}`}>{scene.scene.statusName==='REF'|scene.scene.statusName==='IPI'?'Partial metadata':'Missing'}</div> : null}
                        {scene.scene.statusName !== 'UE'?
                        <button type="button" className={`btn btn-sm m-2 btn-danger`} onClick={updateErrorFlag}>Flag Missing Video</button>:<div className={`badge-pill btn-sm m-2 badge-danger`}>Missing Video, Already Flagged!</div>}
                        


                        <SceneUserData sceneId={props.match.params.id}></SceneUserData>
                    </Row>
                    <Row className="justify-content-between">
                        <div style={{ width: "100%", height: "100%", }}>

                            {!props.isFetchingUserInfo ?
                                (videoHTML) : ""}
                        </div>
                    </Row>
                    {scenePositions.length>0?<Row>
                        <PositionAdder sceneId={props.match.params.id} jumpVideo={jumpVideo} scenePositions={scenePositions}/>
                    </Row>:null}
                    <Row>
                        <Col sm={3} md={4} style={{ marginLeft: "20px" }}>
                            <Row style={{ marginTop: "10px" }}>
                                {actorElem}
                            </Row>
                            <Row><div className="text-muted" style={{ fontSize: "15px" }}>{date.format("D-MMM-YYYY")}</div></Row>
                            <Row className="">
                                <a href={`/scenes?site=${props.scene.site.siteId}`} className="site site-scene">
                                    <div className="site-left-box site">{props.scene.site.shortname}</div>
                                    <div className="site-right-box site">{props.scene.site.name}</div>
                                </a>
                                {props.scene.movie.id !== 0 ?
                                    <a href={`/scenes?movie=${props.scene.movie.id}`} className="site site-scene">
                                        <div className="site-left-box site">{props.scene.movie.scenesNumber}</div>
                                        <div className="site-right-box site">{props.scene.movie.title}</div>
                                    </a> : <div />}
                            </Row>
                        </Col>
                        <Col>
                            <Row className="justify-content-between">
                                <Col><h3>Tags</h3></Col>
                                {props.perms.some(e => e.role.id === 12) ? <Col><UploadFile sceneId={scene.scene.id} /></Col> : null}
                                {props.perms.some(e => e.role.id === 2) ? <Col><EditScene sceneView={true} sceneId={scene.scene.id} /></Col> : null}
                            </Row>
                            {!props.isFetchingSceneTag ? category.map((cat, idx) => (
                                <Row key={`${cat}${idx}`}>
                                    <Col xs={3} sm={3} md={2}>{cat}</Col>
                                    <Col>
                                        <Row>
                                            {helper.findTag(TAGS.SCENECATEGORY, cat, props.sceneTag).map((tag) =>
                                            (<div key={tag.id} className="m-1 badge badge-secondary">
                                                <span> <Link style={{ color: "white" }} to={`/scenes?tag=${tag.id}`}>{tag.name}</Link> </span>
                                            </div>))}
                                        </Row>
                                    </Col>
                                </Row>)) : <div><SpinnerLoad></SpinnerLoad></div>}
                        </Col>
                    </Row>
                    <Row>
                        <div className="text-left mt-4" style={{ fontSize: "16px" }}>{scene.scene.description}</div>
                    </Row>
                    <Row><a href={`${constants.CONTENT_DOMAIN}dload/${scene.scene.videoUrl?.replace('stream/','')}${token}`} onClick={()=>props.downloadHistory(scene.scene.id)}>Download</a></Row>
                    <Row><RelatedScenes /></Row>
                </Container>

            </Container></>
        )
    }
    else {
        return (<SpinnerLoad></SpinnerLoad>);
    }
}
const mapStateToProps = (state) => {
    return {
        isFetching: state.sceneReducers.isFetching,
        scene: state.sceneReducers.scene,
        error: state.sceneReducers.error,

        isFetchingSceneTag: state.sceneReducers.isFetchingSceneTag,
        sceneTag: state.sceneReducers.sceneTag,
        tags: state.sceneReducers.tags,

        relScenes: state.sceneReducers.relatedScenes,

        allStatus: state.scenes.status.allStatus,
        startTime: state.scenes.userData.history.time,
        perms: state.users.permissions.perms
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getScene: (id) => dispatch(sceneActions.getScene(id)),
        getSceneTag: (id) => dispatch(sceneActions.getSceneTag(id)),
        getRelatedScene: ((site, model, movie) => dispatch(sceneActions.getRelatedSceneCard(10, 1, site, "date", "desc", model, "", movie))),
        addToHistory: (id, time) => dispatch(userDataActions.addHistoryScene(id, time)),
        downloadHistory:(id) => dispatch(sceneActions.downloadSceneHistory(id)),
        flagScene: (id) => dispatch(sceneActions.flagSceneError(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SceneView);